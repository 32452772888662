exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/Article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-concept-js": () => import("./../../../src/templates/concept.js" /* webpackChunkName: "component---src-templates-concept-js" */),
  "component---src-templates-gdpr-js": () => import("./../../../src/templates/gdpr.js" /* webpackChunkName: "component---src-templates-gdpr-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-references-js": () => import("./../../../src/templates/references.js" /* webpackChunkName: "component---src-templates-references-js" */),
  "component---src-templates-sertificate-js": () => import("./../../../src/templates/Sertificate.js" /* webpackChunkName: "component---src-templates-sertificate-js" */),
  "component---src-templates-solution-js": () => import("./../../../src/templates/Solution.js" /* webpackChunkName: "component---src-templates-solution-js" */)
}

